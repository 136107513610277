import * as styles from "./activityPreferenceIcon.module.scss";
import React, { FC } from "react";
import { fetchActivityIcon } from "../../../../../utils";

export interface activityPreferencesProps {
  activityName: string;
  checked?: boolean;
}

const ActivityPreferenceIcon: FC<activityPreferencesProps> = ({
  activityName,
  checked,
}) => (
  <img
    className={styles.icon}
    role="button"
    src={
      checked
        ? fetchActivityIcon(activityName, "white")
        : fetchActivityIcon(activityName, "#0C3DEB")
    }
    alt="Activity_icon"
  />
);

export default ActivityPreferenceIcon;
