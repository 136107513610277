import * as styles from "./activityPreferences.module.scss";
import React, { FC, useEffect, useState } from "react";
import { makeStyles, AppBar, Button, Toolbar } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import ActivityPreferenceBoxes from "./activityPreferenceBoxes";
import PreviousButton from "../../../icons/button-previous.svg";
import { fetchActivityTypes } from "../../../redux/actions/activityTypesActions";
import { fetchAuthInfo } from "../../../redux/actions/authActions";
import { muiStyleActivityPreferences } from "./muiStyleActivityPreferences";
import { muiStylesButtons } from "../../CompanySettings/muiStylesCompanySettings";
import { selectActivityPreferencesProcessed } from "../../../redux/selectors/activityPreferencesSelectors";
import { selectActivityTypes } from "../../../redux/selectors/activityTypesSelectors";
import { setActivityTypePreferences } from "../../../redux/actions/activityPreferencesActions";
import { useIsSmall } from "../../../utils";

interface ActivityPreferencesProps {
  toggleActivityPreferences?: () => void;
  userActivityTypes?: number[];
  userId: number;
  onboarding?: boolean;
}

export interface CheckedActivity {
  name: string;
  id: number;
  checked: boolean | undefined;
}

const useButtonStyles = makeStyles(muiStylesButtons);
const useStyles = makeStyles(muiStyleActivityPreferences);

const ActivityPreferences: FC<ActivityPreferencesProps> = ({
  onboarding,
  toggleActivityPreferences,
  userActivityTypes,
  userId,
}) => {
  const isSmall = useIsSmall();
  const allActivityTypes = useSelector(selectActivityTypes);
  const isProcessed = useSelector(selectActivityPreferencesProcessed);
  const classes = useStyles();
  const buttonClasses = useButtonStyles();

  const dispatch = useDispatch();

  const [checkedActivities, setCheckedActivities] = useState<
    CheckedActivity[] | undefined
  >(undefined);

  const updateActivityPreferences = () => {
    const activityTypesToSend = checkedActivities?.filter((activity) => {
      return activity.checked;
    });

    if (activityTypesToSend) {
      const idsToSend = activityTypesToSend.map((activity) => {
        return activity.id;
      });

      dispatch(
        setActivityTypePreferences({ activityTypes: idsToSend, userId })
      );
    }
  };

  const checkActivity = (activity: CheckedActivity) => {
    if (checkedActivities) {
      const indexOfElementToCheck = checkedActivities?.indexOf(activity);

      checkedActivities[indexOfElementToCheck].checked = !checkedActivities[
        indexOfElementToCheck
      ].checked;
      setCheckedActivities([...checkedActivities]);
    }
  };

  const getInitialCheckedActivities = () => {
    if (allActivityTypes && Array.isArray(allActivityTypes)) {
      const InitialCheckedActivities = allActivityTypes?.map((activityType) => {
        const isChecked = userActivityTypes?.includes(activityType.id);

        return {
          name: activityType.name,
          id: activityType.id,
          checked: isChecked,
        };
      });

      setCheckedActivities(InitialCheckedActivities);
    }
  };

  useEffect(() => {
    dispatch(fetchActivityTypes());
  }, [dispatch]);

  useEffect(() => {
    getInitialCheckedActivities();
  }, [allActivityTypes]);

  useEffect(() => {
    if (isProcessed) {
      dispatch(fetchAuthInfo());
    }
  }, [isProcessed]);

  return (
    <>
      <div className={styles.activityPreferenceContainer}>
        {onboarding ? null : (
          <div
            className={styles.returnContainer}
            onClick={toggleActivityPreferences}
          >
            <img
              role="button"
              src={PreviousButton}
              alt="pagination_button_backward"
            />
            <span className={styles.returnText}>
              Change Activity Preferences
            </span>
          </div>
        )}
        <div
          className={
            !isSmall
              ? styles.activityContainer
              : styles.activityContainerElongated
          }
        >
          <ActivityPreferenceBoxes
            checkedActivities={checkedActivities}
            checkActivity={checkActivity}
          />
        </div>
        {isSmall ? (
          <AppBar position="fixed" color="primary" className={classes.appBar}>
            <Toolbar>
              {onboarding ? (
                <Button
                  onClick={updateActivityPreferences}
                  className={`${classes.buttonOnboarding} ${buttonClasses.primaryButton}`}
                  variant="contained"
                  color="primary"
                >
                  Finish
                </Button>
              ) : (
                <>
                  <Button
                    onClick={toggleActivityPreferences}
                    className={`${classes.button} ${classes.buttonCancel}`}
                    variant="outlined"
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={updateActivityPreferences}
                    className={`${classes.button} ${classes.buttonSave}`}
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                </>
              )}
            </Toolbar>
          </AppBar>
        ) : (
          <div className={styles.buttonContainer}>
            <Button
              onClick={updateActivityPreferences}
              className={classes.buttonDesktop}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default ActivityPreferences;
