import { Menu, MenuItem } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { FC, MouseEventHandler, useState } from "react";
import * as styles from "./organizationStructure.module.scss";
import { MenuButton } from "./utils";

interface TeamMenuProps {
  removeTeam: () => void;
  editTeam: () => void;
}

export const TeamMenu: FC<TeamMenuProps> = ({ removeTeam, editTeam }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen: MouseEventHandler<HTMLElement> = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleMenuClose: MouseEventHandler<HTMLElement> = (event) => {
    setAnchorEl(null);
    event.stopPropagation();
  };
  const handleRemoveTeam: MouseEventHandler<HTMLElement> = (event) => {
    removeTeam();
    handleMenuClose(event);
  };
  const handleEditTeam: MouseEventHandler<HTMLElement> = (event) => {
    editTeam();
    handleMenuClose(event);
  };

  return (
    <>
      <MenuButton
        color="inherit"
        aria-label="departament context menu"
        size="small"
        onClick={handleMenuOpen}
        onFocus={(event) => event.stopPropagation()}
      >
        <MoreVertIcon />
      </MenuButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        getContentAnchorEl={null}
        keepMounted
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <div className={styles.linkStyle}>
          <MenuItem onClick={handleRemoveTeam}>Remove department</MenuItem>
        </div>
        <div className={styles.linkStyle}>
          <MenuItem onClick={handleEditTeam}>Edit department</MenuItem>
        </div>
      </Menu>
    </>
  );
};
