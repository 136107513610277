import { RootState } from "../store";

export const selectActivityPreferencesProcessed = (state: RootState) => {
  return state.activityPreferences.processed;
};

export const selectActivityPreferencesError = (state: RootState) => {
  return {
    error: state.activityPreferences.error,
    message: state.activityPreferences.errorMessage,
    details: state.activityPreferences.errorDetails,
  };
};
