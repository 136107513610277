import * as styles from "./loader.module.scss";
import React, { FC } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const Loader: FC<{ loading: boolean }> = ({ loading }) => {
  return (
    <div className={loading ? styles.container : styles.hidden}>
      <CircularProgress size={64} thickness={2.8} />
      <h3 className={styles.loaderText}>Loading</h3>
    </div>
  );
};

export default Loader;
