import { RootState } from "../store";

export interface ActivityTypeOption {
  id: number | string;
  name: string;
}

export const selectActivityPreferencesLoading = (state: RootState) => {
  return state.activityPreferences.loading;
};

export const selectActivityTypes = (state: RootState) => {
  return [...state.activityTypes.activityTypes].sort((a, b) => {
    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
  });
};

export const selectActivityTypesForDropdown = (state: RootState) => {
  return [
    { name: "All Activities", id: "all" },
    ...(state.activityTypes.activityTypes || []),
  ];
};
