import { Theme, createStyles } from "@material-ui/core/styles";
import {
  backgroundGeneralColor,
  borderColor,
  inputBackgroundColor,
  modalBackgroundColor,
  primaryColor,
  primaryColorHover,
  secondaryColor,
  secondaryColorHover,
  textInputBackground,
  warningColor,
  warningColorHover,
} from "../../styles/mui/theme";

const firstColumn = (100 / 4).toFixed(2);

export const muiStylesButtons = (theme: Theme) =>
  createStyles({
    primaryButton: {
      "color": secondaryColor,
      "border": `1px solid ${primaryColor}`,
      "backgroundColor": primaryColor,
      "textTransform": "initial",
      "&:hover": {
        backgroundColor: primaryColorHover,
      },
      "&:disabled": {
        backgroundColor: primaryColor,
        opacity: "0.3",
        color: secondaryColor,
      },
      "&:hover:disabled": {
        backgroundColor: primaryColor,
        opacity: "0.3",
        color: secondaryColor,
      },
    },
    secondaryButton: {
      "color": primaryColor,
      "border": `1px solid ${theme.palette.divider}`,
      "backgroundColor": secondaryColor,
      "textTransform": "initial",
      "&:hover": {
        backgroundColor: secondaryColorHover,
      },
    },
    warningButton: {
      "color": secondaryColor,
      "border": `1px solid ${warningColor}`,
      "backgroundColor": warningColor,
      "textTransform": "initial",
      "&:hover": {
        backgroundColor: warningColorHover,
      },
    },
  });

export const muiStylesOrganizationStructure = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    desktopNotice: {
      padding: "9rem 3rem",
      backgroundColor: backgroundGeneralColor,
      textAlign: "center",
      borderRadius: "10px",
    },
    noDeparmentsNotice: {
      padding: "15rem 3rem",
      textAlign: "center",
      borderRadius: "10px",
    },
    pageHeader: {
      "display": "flex",
      "justifyContent": "space-between",
      "alignItems": "center",
      "paddingTop": "1rem",
      "& h3": {
        [theme.breakpoints.down("sm")]: {
          paddingTop: "16px",
          paddingLeft: "24px",
        },
      },
    },
    btnAddDepartment: {
      marginBottom: "1.45rem",
    },
    heading: {
      fontSize: theme.typography.pxToRem(20),
      lineHeight: 1.2,
      fontWeight: 900,
      flexBasis: `${firstColumn}%`,
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 2,
      color: theme.palette.text.secondary,
      flexGrow: 1,
    },
    innerHeading: {
      fontSize: theme.typography.pxToRem(16),
      flexBasis: `calc(${firstColumn}% - 19px)`,
      flexShrink: 0,
    },
    innerSecondaryHeading: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 2,
      color: theme.palette.text.secondary,
      flexGrow: 1,
    },
    accordionSummary: {
      flexDirection: "row-reverse",
    },
    accordionDetailsLevel1: {
      "flexDirection": "column",
      "padding": 0,
      "&:last-child": {
        borderBottom: 0,
      },
    },
    memberContainer: {
      padding: 0,
      flexDirection: "column",
    },
    memberRow: {
      borderTop: `1px solid ${theme.palette.divider}`,
      width: "calc(100% + 24px)",
      minHeight: 48,
      padding: "8px 16px 16px 76px",
    },
    onboardingTeamRow: {
      justifyContent: "space-between",
      marginLeft: "37px",
    },
    onboardingTeamContainer: {
      display: "flex",
      justifyContent: "space-between",
      padding: "0 16px",
      borderStyle: "solid",
      borderWidth: "1px 0 0 0",
      borderColor,
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    onboardingTeamHeader: {
      marginLeft: "37px",
    },
    expandedAccordion: {
      marginBottom: 0,
    },
  });

export const muiStylesFormControlLabel = () =>
  createStyles({
    root: {
      "width": "100%",
      "alignItems": "start",
      "paddingBottom": "1rem",
      "paddingRight": "1rem",
      "& .MuiFilledInput-root": {
        background: inputBackgroundColor,
      },
      "& .MuiFilledInput-underline:before": {
        "border-bottom": "1px solid transparent",
      },
    },
    disabled: {},
  });

export const muiStylesDialog = (theme: Theme) =>
  createStyles({
    backgroundDialog: {
      "& .MuiBackdrop-root": { "background-color": modalBackgroundColor },
    },
    search: {
      "& .MuiFilledInput-input": {
        padding: "15px 10px",
      },
      "& .MuiFilledInput-adornedStart": {
        "padding-left": "0px",
      },
      "& .MuiFilledInput-root": {
        background: inputBackgroundColor,
      },
      "& .MuiFilledInput-underline:before": {
        "border-bottom": "1px solid transparent",
      },
    },
    dialogPaper: {
      "borderRadius": 8,
      "& .MuiDialogContent-root": {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
    dialogContent: {
      "padding": "0 40px 16px 24px",
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
      "& .MuiTypography-paragraph": {
        padding: "0 1rem",
      },
      "& .MuiFormHelperText-root.Mui-error:first-letter": {
        textTransform: "uppercase",
      },
      "& .MuiFilledInput-root": {
        "background": textInputBackground,
        "&:hover:not($disabled)": {
          background: textInputBackground,
        },
        "&:focus:not($disabled)": {
          background: textInputBackground,
        },
        "&:autofill": {
          background: textInputBackground,
        },
        "&&&:before": {
          borderBottom: "none",
        },
      },
    },
    dialogTitle: {
      "display": "flex",
      "justifyContent": "space-between",
      "alignItems": "flex-start",
      "paddingLeft": "40px",
      "& .MuiIconButton-root": {
        position: "relative",
        left: ".5rem",
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "1rem",
      },
      "& h5": {
        marginTop: "1.75rem",
        marginBottom: "1rem",
        [theme.breakpoints.down("sm")]: {
          margin: 0,
        },
      },
    },
    dialogActions: {
      "padding": "20px 40px",
      [theme.breakpoints.down("sm")]: {
        padding: "1rem",
      },
      "& .MuiButton-root": {
        padding: "6px 40px",
        [theme.breakpoints.down("sm")]: {
          padding: "6px 12px",
        },
      },
    },
    input: {
      marginLeft: "16px",
    },
    disabled: {},
  });
