// extracted by mini-css-extract-plugin
export var h1Typography = "loader-module--h1-typography--2YH89";
export var h2Typography = "loader-module--h2-typography--Brlpi";
export var h3Typography = "loader-module--h3-typography--1r9zm";
export var h4Typography = "loader-module--h4-typography--T6WUt";
export var h5Typography = "loader-module--h5-typography--3KnDR";
export var h6Typography = "loader-module--h6-typography--ugJTc";
export var pTypography = "loader-module--p-typography--12umv";
export var info = "loader-module--info--1junj";
export var buttonTypography = "loader-module--button-typography--38xGw";
export var formLabelTypography = "loader-module--form-label-typography--2vG3A";
export var menuTabTypography = "loader-module--menu-tab-typography--3wZPs";
export var linkStyle = "loader-module--link-style--2Hjz8";
export var postTypography = "loader-module--post-typography--3y-KH";
export var container = "loader-module--container--2T9T7";
export var loaderText = "loader-module--loader-text--2MeSS";
export var hidden = "loader-module--hidden--2Dx8D";