import {
  AccordionProps,
  Button,
  IconButton,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  Collapse as MuiCollapse,
  withStyles,
} from "@material-ui/core";

export const Accordion = withStyles((theme) => ({
  root: {
    "width": "100%",
    "backgroundColor": "transparent",
    "border": `1px solid ${theme.palette.divider}`,
    "borderRight": "none",
    "borderLeft": "none",
    "boxShadow": "none",
    "paddingLeft": "24px",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      "margin": "auto",
      "&:last-child": {
        borderBottom: 0,
      },
    },
  },
  rounded: {
    "backgroundColor": theme.palette.background.paper,
    "borderRadius": 0,
    "paddingLeft": 0,
    "&:first-child": {
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    "&:last-child": {
      "borderBottomLeftRadius": 10,
      "borderBottomRightRadius": 10,
      // Fix a rendering issue on Edge
      "@supports (-ms-ime-align: auto)": {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
    "&$expanded": {
      "&:last-child": {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  },
  expanded: {},
}))(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    "minHeight": 48,
    "&$expanded": {
      minHeight: 48,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
  expandIcon: {
    "transform": "rotate(-90deg)",
    "&$expanded": {
      transform: "rotate(0deg)",
    },
  },
})(MuiAccordionSummary);

export const Collapse = withStyles((theme) => ({
  container: {
    height: 0,
    overflow: "hidden",
    marginLeft: "-24px",
    transition: theme.transitions.create("height"),
  },
}))(MuiCollapse) as AccordionProps["TransitionComponent"];

export const AddTeamButton = withStyles({
  root: {
    textTransform: "capitalize",
    fontWeight: "bold",
  },
  sizeSmall: {
    margin: "-4px 5px",
  },
})(Button);

export const MenuButton = withStyles({
  sizeSmall: {
    margin: "-4px 5px",
  },
})(IconButton);
