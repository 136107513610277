// extracted by mini-css-extract-plugin
export var h1Typography = "activityPreferenceBoxes-module--h1-typography--3YgXb";
export var h2Typography = "activityPreferenceBoxes-module--h2-typography--6QoJS";
export var h3Typography = "activityPreferenceBoxes-module--h3-typography--2d4vf";
export var h4Typography = "activityPreferenceBoxes-module--h4-typography--2Yppn";
export var h5Typography = "activityPreferenceBoxes-module--h5-typography--1S_2D";
export var h6Typography = "activityPreferenceBoxes-module--h6-typography--1etsj";
export var pTypography = "activityPreferenceBoxes-module--p-typography--1TAui";
export var info = "activityPreferenceBoxes-module--info--3GbYO";
export var buttonTypography = "activityPreferenceBoxes-module--button-typography--5KIfZ";
export var formLabelTypography = "activityPreferenceBoxes-module--form-label-typography--33Kbb";
export var menuTabTypography = "activityPreferenceBoxes-module--menu-tab-typography--C0OgC";
export var linkStyle = "activityPreferenceBoxes-module--link-style--SK0GZ";
export var postTypography = "activityPreferenceBoxes-module--post-typography--1D-UH";
export var activityBoxesContainer = "activityPreferenceBoxes-module--activity-boxes-container--284FX";
export var activityBoxContainer = "activityPreferenceBoxes-module--activity-box-container--5kNAk";
export var checked = "activityPreferenceBoxes-module--checked--kFtry";