// extracted by mini-css-extract-plugin
export var h1Typography = "organizationStructure-module--h1-typography--nhfm6";
export var h2Typography = "organizationStructure-module--h2-typography--1G26h";
export var h3Typography = "organizationStructure-module--h3-typography--1GOCL";
export var h4Typography = "organizationStructure-module--h4-typography--2TIrR";
export var h5Typography = "organizationStructure-module--h5-typography--2dy3G";
export var h6Typography = "organizationStructure-module--h6-typography--1Fz4w";
export var pTypography = "organizationStructure-module--p-typography--2Z0BA";
export var info = "organizationStructure-module--info--L4fyi";
export var buttonTypography = "organizationStructure-module--button-typography--301AG";
export var formLabelTypography = "organizationStructure-module--form-label-typography--2SRiR";
export var menuTabTypography = "organizationStructure-module--menu-tab-typography--2ybDo";
export var linkStyle = "organizationStructure-module--link-style--3P7u0";
export var postTypography = "organizationStructure-module--post-typography--25CxD";