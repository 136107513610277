import * as yup from "yup";

const requiredNotEmptyString = yup
  .string()
  .min(1)
  .required("This field is required");

export const companySettingsSchema = yup.object().shape({
  name: requiredNotEmptyString,
  address: requiredNotEmptyString,
  domain: requiredNotEmptyString,
});
