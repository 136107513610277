import { Theme, createStyles } from "@material-ui/core";
import {
  inputBackgroundColor,
  primaryColor,
  primaryColorHover,
  secondaryColor,
  secondaryColorHover,
  warningColor,
  warningColorHover,
} from "../../styles/mui/theme";

const verticalSplit = 330;
const padding = 40;

export const muiStylesCompanySettings = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    pageHeader: {
      "display": "flex",
      "justifyContent": "space-between",
      "alignItems": "center",
      "paddingTop": "1rem",
      "& h3": {
        [theme.breakpoints.down("sm")]: {
          paddingTop: "16px",
          paddingLeft: "24px",
        },
      },
    },
    logo: {
      "display": "flex",
      "alignItems": "center",
      "flexDirection": "column",
      "padding": `${theme.typography.pxToRem(padding)}`,
      "textAlign": "center",
      "borderRadius": "10px 0 0 10px",
      "flexBasis": `${verticalSplit}px`,
      "flexShrink": 0,
      "borderRight": `1px solid ${theme.palette.divider}`,
      [theme.breakpoints.down("sm")]: {
        padding: `1rem`,
        flexBasis: "initial",
        borderRadius: "10px 10px 0 0",
        borderRight: `none`,
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
      "& .MuiInputLabel-root": {
        marginRight: "auto",
        textTransform: "uppercase",
        fontSize: `${theme.typography.pxToRem(13)}`,
        lineHeight: 2.5,
        color: "rgba(0, 0, 0, 0.87)",
      },
    },
    logoContainer: {
      "width": "100%",
      "border": `1px solid ${theme.palette.divider}`,
      "& .MuiAvatar-square": {
        "width": "248px",
        "height": "248px",
        "padding": "1rem",
        "margin": "auto",
        "& .MuiSvgIcon-root": {
          height: "auto",
        },
      },
    },
    details: {
      "padding": `${theme.typography.pxToRem(padding)}`,
      "borderRadius": "0 10px 10px 0",
      "flex": 1,
      "& .MuiFormHelperText-root.Mui-error:first-letter": {
        textTransform: "uppercase",
      },
      "& form": {
        marginBottom: "0.5rem",
      },
      [theme.breakpoints.down("sm")]: {
        "padding": `1rem`,
        "borderRadius": "0 0 10px 10px",
        "& form": {
          marginBottom: 0,
        },
      },
    },
    buttons: {
      "flex": "0 0 auto",
      "display": "flex",
      "alignItems": "center",
      "justifyContent": "flex-end",
      "paddingTop": "4.625rem",
      "& .MuiButton-root": {
        padding: "6px 40px",
        marginLeft: "8px",
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: "0.75rem",
      },
    },
  });

export const muiStylesFormControlLabel = (theme: Theme) =>
  createStyles({
    root: {
      "width": "100%",
      "alignItems": "start",
      "paddingBottom": "1rem",
      "margin": 0,
      [theme.breakpoints.down("sm")]: {
        paddingBottom: 0,
      },
      "& .MuiFormControlLabel-label": {
        textTransform: "uppercase",
        fontSize: `${theme.typography.pxToRem(13)}`,
        lineHeight: 2.5,
      },
      "& .MuiInputBase-input": {
        padding: "13px 16px 12px",
        [theme.breakpoints.down("sm")]: {
          padding: "13px 7px 12px",
        },
      },
      "& .MuiFilledInput-root": {
        background: inputBackgroundColor,
      },
      "& .MuiFilledInput-underline:before": {
        "border-bottom": "1px solid transparent",
      },
    },
    disabled: {},
  });

export const muiStylesButtons = (theme: Theme) =>
  createStyles({
    primaryButton: {
      "color": secondaryColor,
      "border": `1px solid ${primaryColor}`,
      "backgroundColor": primaryColor,
      "textTransform": "initial",
      "&:hover": {
        backgroundColor: primaryColorHover,
      },
      "&:disabled": {
        backgroundColor: primaryColor,
        opacity: "0.3",
        color: secondaryColor,
      },
      "&:hover:disabled": {
        backgroundColor: primaryColor,
        opacity: "0.3",
        color: secondaryColor,
      },
    },
    secondaryButton: {
      "color": primaryColor,
      "border": `1px solid ${theme.palette.divider}`,
      "backgroundColor": secondaryColor,
      "textTransform": "initial",
      "&:hover": {
        backgroundColor: secondaryColorHover,
      },
      "& + &": {
        marginLeft: "auto",
        padding: "6px 40px",
      },
    },
    warningButton: {
      "color": secondaryColor,
      "border": `1px solid ${warningColor}`,
      "backgroundColor": warningColor,
      "textTransform": "initial",
      "&:hover": {
        backgroundColor: warningColorHover,
      },
    },
  });
