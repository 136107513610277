import * as React from "react";
import * as styles from "./activityPreferenceBoxes.module.scss";
import { FC } from "react";
import { CheckedActivity } from "../index";
import ActivityPreferenceIcon from "./activityPreferenceIcon";
import { customActivityTypeName } from "../../../../utils";

export interface ActivityPreferenceBoxesProps {
  checkedActivities?: CheckedActivity[];
  checkActivity(arg0: CheckedActivity): void;
}

const ActivityPreferenceBoxes: FC<ActivityPreferenceBoxesProps> = ({
  checkedActivities,
  checkActivity,
}) => (
  <div className={styles.activityBoxesContainer}>
    {checkedActivities?.map((activity) => {
      if (activity.name !== customActivityTypeName) {
        return (
          <div
            className={`${styles.activityBoxContainer} ${
              activity.checked ? styles.checked : ""
            }`}
            key={`${activity.name}_${activity.id}`}
            onClick={() => {
              checkActivity(activity);
            }}
          >
            <div>
              <ActivityPreferenceIcon
                activityName={activity.name}
                checked={activity.checked}
              />
            </div>
            <div>{activity.name}</div>
          </div>
        );
      }
    })}
  </div>
);

export default ActivityPreferenceBoxes;
