// extracted by mini-css-extract-plugin
export var h1Typography = "activityPreferences-module--h1-typography--1bB9B";
export var h2Typography = "activityPreferences-module--h2-typography--3fyUe";
export var h3Typography = "activityPreferences-module--h3-typography--13tN7";
export var h4Typography = "activityPreferences-module--h4-typography--1_Fo0";
export var h5Typography = "activityPreferences-module--h5-typography--3ObWF";
export var h6Typography = "activityPreferences-module--h6-typography--9RU0B";
export var pTypography = "activityPreferences-module--p-typography--3xHa9";
export var info = "activityPreferences-module--info--3Hpet";
export var buttonTypography = "activityPreferences-module--button-typography--3vafB";
export var formLabelTypography = "activityPreferences-module--form-label-typography--Kc2Z9";
export var menuTabTypography = "activityPreferences-module--menu-tab-typography--26m79";
export var linkStyle = "activityPreferences-module--link-style--1qyjw";
export var postTypography = "activityPreferences-module--post-typography--h9OKE";
export var activityPreferenceContainer = "activityPreferences-module--activity-preference-container--1N4oF";
export var returnContainer = "activityPreferences-module--return-container--Pusi_";
export var returnText = "activityPreferences-module--return-text--3o-9g";
export var activityContainer = "activityPreferences-module--activity-container--k9sNC";
export var activityContainerElongated = "activityPreferences-module--activity-container-elongated--1LB_1";
export var error = "activityPreferences-module--error--X-5yr";
export var accountSettingsContainer = "activityPreferences-module--account-settings-container--1sNbz";
export var buttonContainer = "activityPreferences-module--button-container--2RuHa";