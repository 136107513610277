import store, { RootState } from "../store";
import { fetchAuthInfo } from "../actions/authActions";

/*
 * Employee onboarding selector returning a current user, without any additional action,
 * if the user is not defined.
 */
export const selectAuthUserDummy = (state: RootState) => {
  return state.authUser.user;
};

export const selectAuthUser = (state: RootState) => {
  if (!state.authUser?.user && !state.authUser?.loading) {
    // if user info is not available (e.g. after the page reload) - fetch it
    const { dispatch } = store;

    void dispatch(fetchAuthInfo());
  }

  return state.authUser.user;
};

export const selectAuthUserLoading = (state: RootState) => {
  return state.authUser.loading;
};

export const selectAuthError = (state: RootState) => {
  return {
    error: state.authUser.error,
    message: state.authUser.errorMessage,
    details: state.authUser.errorDetails,
  };
};
